import React, { Component } from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import axios from 'axios';

import Form from '../components/Form';
import wrapInSection from '../components/wrapInSection';
import wrapInLayout from '../components/wrapInLayout';
import Loading from '../components/Loading';
import FormFailMessage from '../components/FormFailMessage';
import { getTokenFromLocation } from '../helpers';

const fields = [
  {
    name: 'newPassword',
    label: 'Новый пароль',
    type: 'password',
  },
];

const validate = values => {
  const errors = {};

  if (!values.newPassword) {
    errors.newPassword = 'Обязательное поле';
  }

  return errors;
};

const ResetPasswordForm = ({ email, token }) => (
  <div>
    <Form
      submitUrl={`/auth/reset_password?token=${token}`}
      fields={fields}
      form="forgotPassword"
      validate={validate}
      successMessage={() => (
        <div>
          <p className="lead text-success">
            Пароль установлен <i className="fa fa-lock" />
          </p>
          <Link to={'/login'} className="btn btn-primary btn-block">
            Войти
          </Link>
        </div>
      )}
    >
      <p>
        Введите новый пароль для <strong>{email}</strong>
      </p>
    </Form>
  </div>
);

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      error: false,
    };
  }

  componentDidMount() {
    const token = getTokenFromLocation(this.props.location);

    axios
      .get('/api/auth/reset_password', { params: { token } })
      .then(({ data: { email } }) => this.setState({ email }))
      .catch(error => {
        let errorMessage;
        try {
          errorMessage = error.response.data.errors[0].message;
        } finally {
          this.setState({
            errorMessage,
            error: true,
          });
        }
      });
  }

  render() {
    const { error, email, errorMessage } = this.state;
    const token = getTokenFromLocation(this.props.location);

    let component;

    if (error) {
      component = <FormFailMessage message={errorMessage} />;
    } else if (email) {
      component = <ResetPasswordForm email={email} token={token} />;
    } else {
      component = <Loading />;
    }

    return (
      <div className="page-wrapper">
        <Helmet title="КЭСПА – Восстановление пароля" />
        <div className="row">
          <div className="col-md-5">
            <h2>Восстановление пароля</h2>
            {component}
          </div>
        </div>
      </div>
    );
  }
}

export default wrapInLayout(wrapInSection(ResetPassword));
